<template>
  <div
    class="d-flex"
  >
    <v-spacer />
    <v-btn
      color="primary"
      class="my-3 hidden-xs-only"
      :disabled="!procedureTemplateCreationEnabled"
      @click="clickCreate"
    >
      <v-icon>mdi-plus</v-icon>
      Crea un modello
    </v-btn>
    <v-btn
      color="primary"
      class="my-3 hidden-sm-and-up"
      block
      :disabled="!procedureTemplateCreationEnabled"
      @click="clickCreate"
    >
      <v-icon>mdi-plus</v-icon>
      Crea un modello
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProcedureTemplateHeader',
  props: {
    procedureTemplateCreationEnabled: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['loggedUser']),
  },
  methods: {
    clickCreate() {
      this.$emit('clickCreate')
    },
  },
}
</script>
