<template>
  <div>
    <v-dialog
      v-model="isShow"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Crea un modello di pratica</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            @submit.prevent="create"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.title"
                  label="Titolo"
                  outlined
                  required
                  dense
                  :rules="[formRules.required]"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="form.description"
                  label="Descrizione"
                  rows="3"
                  dense
                  outlined
                ></v-textarea>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
              >
                <v-btn
                  class="ma-2"
                  :disabled="loading"
                  @click="closeModal"
                >
                  Chiudi
                </v-btn>
                <v-btn
                  color="primary"
                  class="ma-2"
                  :loading="loading"
                  @click.prevent="create"
                >
                  Crea
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ProcedureTemplateCreate',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['formRules']),
  },
  methods: {
    async create() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          const respCreate = await this.$api.createProcedureTemplate(this.form)
          if (respCreate.data.status === 'success') {
            await this.$store.dispatch('getProcedureTemplates')
            this.closeModal()
            this.goToProcedureTemplate(respCreate.data.procedure_template_id)
            await this.$store.dispatch('set_alert', { msg: respCreate.data.message, type: 'success' })
          } else {
            await this.$store.dispatch('set_alert', { msg: respCreate.data.message, type: 'error' })
            this.loading = false
          }
        } catch (e) {
          await this.$store.dispatch('set_alert', { msg: e.response.data.message, type: 'error' })
          this.loading = false
        }
      }
    },
    closeModal() {
      this.$refs.form.reset()
      this.loading = false
      this.$emit('close')
    },
    goToProcedureTemplate(tid) {
      this.$router.push({ name: 'procedureTemplateView', params: { id: tid } })
    },
  },
}
</script>

<style scoped>

</style>
