<template>
  <div>
    <procedure-template-header
      v-if="loggedUser.role === 'professional'"
      :procedure-template-creation-enabled="procedureTemplateCreationEnabled"
      @clickCreate="isShow.create=true"
    />

    <v-data-iterator
      :items="procedureTemplateList"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
      no-data-text="Nessun modello disponibile"
      no-results-text="Nessun modello corrispondente"
    >
      <template v-slot:header>
        <v-toolbar
          dark
          color="primary"
          class="mb-1"
        >
          <v-text-field
            v-model="search"
            clearable
            flat
            dense
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Cerca"
          ></v-text-field>
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <v-select
              v-model="sortBy"
              flat
              dense
              solo-inverted
              hide-details
              :items="keys"
              prepend-inner-icon="mdi-arrow-up-down"
              label="Sort by"
            ></v-select>
            <v-spacer></v-spacer>
            <v-btn-toggle
              v-model="sortDesc"
              mandatory
            >
              <v-btn
                small
                depressed
                color="primary"
                :value="false"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                small
                depressed
                color="primary"
                :value="true"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="(template,idx) in props.items"
            :key="'PT' + idx"
            cols="12"
          >
            <procedure-template-card
              :procedure-template-obj="template"
            />
          </v-col>
        </v-row>
      </template>

      <template v-slot:footer>
        <v-pagination
          v-model="page"
          :length="numberOfPages"
        ></v-pagination>
      </template>
    </v-data-iterator>
    <procedure-template-create
      :is-show="isShow.create"
      @close="isShow.create=false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProcedureTemplateHeader from '@/components/section-headers/procedure-template-header.vue'
import ProcedureTemplateCard from '@/components/cards/procedure-template-card.vue'
import ProcedureTemplateCreate from '../../components/modals/procedure-template-create.vue'

export default {
  name: 'Index',
  components: {
    ProcedureTemplateHeader,
    ProcedureTemplateCard,
    ProcedureTemplateCreate,
  },
  computed: {
    ...mapGetters(['loggedUser', 'procedureTemplateList']),
    numberOfPages() {
      return Math.ceil(this.procedureTemplateList.length / this.itemsPerPage)
    },
    procedureTemplateCreationEnabled() {
      return true
    },
  },
  data() {
    return {
      isShow: {
        create: false,
      },
      search: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 5,
      sortBy: 'title',
      keys: [
        { text: 'Nome modello', value: 'title' },
        { text: 'Data creazione', value: 'created_at' },
      ],
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
        {
          text: 'I miei modelli',
          to: '/procedure-templates',
        },
      ],
    }
  },
  mounted() {
    this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
  },
}
</script>

<style scoped>

</style>
